<template>
    <div class="fullscreen-element" v-loading="loading">
        <div class="prtf-page-item-title-buttonned">
            <div class="prtf-page-item-title">{{title}}</div>
            <div class="spacer"></div>

            <div class="prtf-page-item-data-btns" v-if="!collapsed">
                <slot name="buttons"></slot>
            </div>
            <div class="prtf-page-item-view-btns">
                <div v-if="!collapsed && canExport">
                    <el-button type="text" @click="export_data"><i class="vxe-icon--download"></i></el-button>
                </div>
                <div v-if="!fullscreen && canFullscreen">
                    <el-button type="text" @click="toggle_fullscreen"><svg-icon icon-class="fullscreen" /></el-button>
                </div>
                <div v-else-if="canFullscreen">
                    <el-button type="text" @click="toggle_fullscreen"><i class="el-icon-close"></i></el-button>
                </div>
                <div v-if="!fullscreen && !collapsed">
                    <el-button type="text" @click="toggle_collapse"><svg-icon icon-class="chevron_up" /></el-button>
                </div>
                <div v-if="!fullscreen && collapsed">
                    <el-button type="text" @click="toggle_collapse"><svg-icon icon-class="chevron_down" /></el-button>
                </div>
            </div>
        </div>

        <template v-if="!collapsed">
            <div class="prtf-page-item-subtitle">
                <slot name="subtitle"></slot>
            </div>

            <template v-if="isTable">
                <vxe-table 
                    :data="list" 
                    size="mini" 
                    class="common-grid" 
                    auto-resize
                    :max-height="table_max_height"
                    ref="xGrid">
                    <slot></slot>
                </vxe-table>
            </template>
            <template v-else>
                <slot></slot>
            </template>
            
        </template>
    </div>
</template>

<script>
import table_height from "@/mixins/table_height";
export default {
    mixins: [table_height],
    props: {
        title: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        canExport: {
            type: Boolean,
            default: false,
        },
        canFullscreen: {
            type: Boolean,
            default: true,
        },
        isTable: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            default: () => []
        },
    },

    data(){
        return {
            fullscreen : false,
            collapsed  : false,
        }
    },

    methods: {
        toggle_fullscreen(){
            this.$fullscreen.toggle(this.$el, {
                teleport: true,
                pageOnly: true,
                callback: (isFullscreen) => {
                    this.fullscreen = isFullscreen
                    this.collapsed = false
                },
            })
        },
        toggle_collapse(){
            this.collapsed = !this.collapsed
        },
        export_data(){
            if (this.$refs.xGrid)
                this.$refs.xGrid.exportData({
                    filename:this.title,
                    sheetName: 'Sheet1',
                    type: 'xlsx'
                });

            if (this.$scopedSlots.default()[0].context.$refs.xGrid)
                this.$scopedSlots.default()[0].context.$refs.xGrid.exportData({
                    filename:this.title,
                    sheetName: 'Sheet1',
                    type: 'xlsx'
                });
        },
    },
    watch: {
        fullscreen: {
            deep: true,
            handler(val){
                this.$emit('toggle-fullscreen', val)
            }
        },
        collapsed: {
            deep: true,
            handler(val){
                this.$emit('toggle-collapsed', val)
            }
        }
    }
}
</script>
