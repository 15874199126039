var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageBlock',{attrs:{"title":"Analytics Summary","loading":_vm.loading,"can-export":"","can-fullscreen":"","is-table":"","list":_vm.list}},[_c('vxe-table-column',{attrs:{"field":"dt","title":"Date","width":"120","formatter":"formatDateTime","fixed":"left"}}),_c('vxe-table-column',{attrs:{"title":"Market Value","field":"mv","min-width":"90","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.mv,"decimals":2,"color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Daily CF (all assets)","field":"d_cash_flow","min-width":"90","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.d_cash_flow,"decimals":2,"color":"","sign":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Total CF","field":"t_cash_flow","min-width":"100","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.t_cash_flow,"decimals":2,"color":"","sign":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Daily TWR Coef","field":"twr_coef","min-width":"100","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.twr_coef,"decimals":4,"color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Daily TWR Index","field":"twr_ind","min-width":"100","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.twr_ind,"decimals":4,"color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"TWR","field":"twr","min-width":"100","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.twr,"format":"0.0000%","color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"TWR index max","field":"twr_max","min-width":"120","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.twr_max,"decimals":4,"color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Drawdown","field":"drawdown","min-width":"120","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.drawdown,"format":"0.0000%","color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"MWR","field":"mwr","min-width":"100","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.mwr,"format":"0.0000%","color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Portfolio Return","field":"daily_return","min-width":"100","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.daily_return,"format":"0.0000%","color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Portfolio Cumulative Return","field":"cumulative_return","min-width":"100","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.cumulative_return,"decimals":4,"zeros":"","color":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Annualized Portfolio Return","field":"annualized_return","min-width":"100","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.annualized_return,"format":"0.0000%","zeros":"","color":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Risk-Free Rate Return","field":"rf_rate_return","min-width":"100","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.rf_rate_return,"format":"0.00000000%","color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Risk-Free Rate Index","field":"rf_rate_ind","min-width":"100","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.rf_rate_ind,"decimals":8,"color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Annualized Risk-Free Rate Return","field":"rf_rate_ann","min-width":"100","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.rf_rate_ann,"format":"0.0000%","color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Benchmark Return","field":"bench_return","min-width":"100","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.bench_return,"format":"0.0000%","color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Benchmark Index","field":"bench_ind","min-width":"100","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.bench_ind,"decimals":8,"color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Annualized Benchmark Return","field":"bench_ann","min-width":"100","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.bench_ann,"format":"0.0000%","color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Cov(Port,Bench)","field":"cov_beta","min-width":"120","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.cov_beta,"decimals":8,"color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Var(Port)","field":"var_beta","min-width":"120","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.var_beta,"decimals":8,"color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Beta","field":"beta","min-width":"120","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.beta,"decimals":8,"color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Expected Return","field":"exp_return","min-width":"120","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.exp_return,"format":"0.0000%","color":"","zeros":""}})]}}])}),_c('vxe-table-column',{attrs:{"title":"Jensen Alpha","field":"jensen_alpha","min-width":"120","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('FormattedNumber',{attrs:{"value":row.jensen_alpha,"format":"0.0000%","color":"","zeros":""}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }