<template>
    <PageBlock 
        title="Analytics Summary"
        :loading="loading"
        can-export
        can-fullscreen
        is-table
        :list="list">
            
        <vxe-table-column 
            field="dt" 
            title="Date" 
            width="120"
            formatter="formatDateTime"
            fixed="left">
        </vxe-table-column>

        <vxe-table-column 
            title="Market Value" 
            field="mv" 
            min-width="90"
            align="right" >
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.mv"
                    :decimals="2"
                    color
                    zeros/>
            </template>
        </vxe-table-column>

        <!-- <vxe-table-column 
            title="Daily CF (cash)" 
            field="assets.cash.d_cash_flow" 
            min-width="90"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.assets.cash.d_cash_flow"
                    :decimals="2"
                    color
                    sign
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Daily CF (bond)" 
            field="assets.bond.d_cash_flow" 
            min-width="90"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.assets.bond.d_cash_flow"
                    :decimals="2"
                    color
                    sign
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Daily CF (equity)" 
            field="assets.equity.d_cash_flow"
            min-width="90"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.assets.equity.d_cash_flow"
                    :decimals="2"
                    color
                    sign
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Daily CF (future)" 
            field="assets.future.d_cash_flow"
            min-width="90"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.assets.future.d_cash_flow"
                    :decimals="2"
                    color
                    sign
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Daily CF (option)" 
            field="assets.option.d_cash_flow"
            min-width="90"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.assets.option.d_cash_flow"
                    :decimals="2"
                    color
                    sign
                    zeros/>
            </template>
        </vxe-table-column> -->
        <vxe-table-column 
            title="Daily CF (all assets)" 
            field="d_cash_flow"
            min-width="90"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.d_cash_flow"
                    :decimals="2"
                    color
                    sign
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Total CF" 
            field="t_cash_flow"
            min-width="100"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.t_cash_flow"
                    :decimals="2"
                    color
                    sign
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Daily TWR Coef" 
            field="twr_coef"
            min-width="100"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.twr_coef"
                    :decimals="4"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Daily TWR Index"
            field="twr_ind" 
            min-width="100"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.twr_ind"
                    :decimals="4"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="TWR" 
            field="twr" 
            min-width="100"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.twr"
                    format="0.0000%"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="TWR index max" 
            field="twr_max" 
            min-width="120"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.twr_max"
                    :decimals="4"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Drawdown" 
            field="drawdown" 
            min-width="120"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.drawdown"
                    format="0.0000%"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="MWR" 
            field="mwr" 
            min-width="100"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.mwr"
                    format="0.0000%"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Portfolio Return" 
            field="daily_return" 
            min-width="100"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.daily_return"
                    format="0.0000%"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Portfolio Cumulative Return" 
            field="cumulative_return" 
            min-width="100"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.cumulative_return"
                    :decimals="4"
                    zeros
                    color/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Annualized Portfolio Return" 
            field="annualized_return" 
            min-width="100"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.annualized_return"
                    format="0.0000%"
                    zeros
                    color/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Risk-Free Rate Return" 
            field="rf_rate_return" 
            min-width="100"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.rf_rate_return"
                    format="0.00000000%"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Risk-Free Rate Index" 
            field="rf_rate_ind" 
            min-width="100"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.rf_rate_ind"
                    :decimals="8"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Annualized Risk-Free Rate Return" 
            field="rf_rate_ann" 
            min-width="100"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.rf_rate_ann"
                    format="0.0000%"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Benchmark Return" 
            field="bench_return" 
            min-width="100"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.bench_return"
                    format="0.0000%"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Benchmark Index" 
            field="bench_ind" 
            min-width="100"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.bench_ind"
                    :decimals="8"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Annualized Benchmark Return" 
            field="bench_ann" 
            min-width="100"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.bench_ann"
                    format="0.0000%"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Cov(Port,Bench)" 
            field="cov_beta" 
            min-width="120"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.cov_beta"
                    :decimals="8"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Var(Port)" 
            field="var_beta" 
            min-width="120"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.var_beta"
                    :decimals="8"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Beta" 
            field="beta" 
            min-width="120"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.beta"
                    :decimals="8"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Expected Return" 
            field="exp_return" 
            min-width="120"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.exp_return"
                    format="0.0000%"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
        <vxe-table-column 
            title="Jensen Alpha" 
            field="jensen_alpha" 
            min-width="120"
            align="right">
            <template #default="{ row }">
                <FormattedNumber
                    :value="row.jensen_alpha"
                    format="0.0000%"
                    color
                    zeros/>
            </template>
        </vxe-table-column>
    </PageBlock>
</template>

<script>
import PageBlock from '@/components/PageBlock'
import FormattedNumber from '@/components/Formats/FormattedNumber'

export default {
    name: 'portfolio_twr',
    components: { FormattedNumber, PageBlock },

    props: {
        clientid: {
            required: true,
        },
        portfolioid: {
            required: true,
        },
        mode: {
            required: true,
        },
        period_from: {
            required: true,
        },
        period_to: {
            required: true,
        },
    },

    data(){
        return {
            list    : [],
            loading : false,
        }
    },

    methods: {
        get_list(){
            this.loading = true
            let search_params = {
                container    : 'portfolio_id',
                container_id : this.portfolioid,
                client_id    : this.clientid,
                date_from    : this.period_from,
                date_to      : this.period_to,
                mode         : this.mode,
            }
            this.$store.dispatch('portfolio/getAnalyticsFull', search_params).then((response) => {
                this.list = response
                this.loading = false
            })
        }
    },

    mounted(){
        this.get_list()
    }
}
</script>
