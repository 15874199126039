export default {
    data(){
        return {
          table_max_height: 450
        }
    },
    methods: {
        calc_table_max_height(){
            let container = null
            let height_reducer = 0
            if(this.fullscreen){
                container = window.document.body
                height_reducer = 80
            } else {
                container = this.$el.closest('.prtf-page')
                height_reducer = 160
            }

            let container_height = 0
            if(container) container_height = container.offsetHeight

            if(container_height){
                if(container_height < 400) container_height = 400;
                this.table_max_height = container_height - height_reducer

                if(!this.fullscreen && this.table_max_height > 305)
                    this.table_max_height = 305

                if(this.table_max_height < 70)
                    this.table_max_height = 70
            }
        }
    },
    mounted() {
        window.addEventListener("resize", this.calc_table_max_height)
    },
    watch: {
        list: {
            handler(){
                this.calc_table_max_height()
            },
            deep: true
        },
        fullscreen: {
            handler(){
                this.calc_table_max_height()
            },
            deep: true
        }
    }
}
